<template>
  <div>
    <a-row style="margin-bottom: 10px">
      <a-form layout="inline" :form="tableForm2">
        <a-form-item :label="$t('table.gps.carlistFrom.gpsId')">
          <a-input v-model="gpsId" disabled></a-input>
        </a-form-item>
        <a-form-item :label="$t('table.gps.carlistFrom.time')">
          <a-range-picker
            v-decorator="validateFields.dates"
            :ranges="{
              Today: [moment(), moment()],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }"
            :format="dateFormat"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="GetGpsList"
            v-t.preserve="'operate.search2'"
          ></a-button>
        </a-form-item>
      </a-form>
    </a-row>
    <div class="map_box">
      <GmapMap
        :center="center"
        :zoom="7"
        map-type-id="roadmap"
        style="width: 100%; height: 700px"
        ref="mapRef"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m"
          :clickable="true"
          :draggable="false"
          @click="center = m"
          :icon="{ url: require('@/assets/home.svg') }"
        >
        </GmapMarker>

        <GmapMarker
          v-if="markpostion.lat"
          :position="markpostion"
          :clickable="true"
          :draggable="false"
          @click="center = markpostion"
        >
        </GmapMarker>

        <GmapPolyline
          :path="item.path"
          :options="item.options"
          v-for="item in polyArr"
          :key="item.gpsId"
        ></GmapPolyline>
      </GmapMap>
    </div>
  </div>
</template>
<script>
import { apiGetGpsRecords } from "@/api/gps";
import moment from "moment";
export default {
  data() {
    return {
      tableForm2: this.$form.createForm(this, { name: "tableForm2" }),
      validateFields: {
        dates: [
          "dates",
          {
            initialValue: [
              moment(new Date().setMonth(new Date().getMonth() - 1)),
              moment(),
            ],
          },
        ],
      },
      gpsId: this.$route.query.gpsId,
      gpsList: [],
      checkGpsList: [],
      dateFormat: "YYYY-MM-DD",
      center: { lat: -0.734007, lng: 34.581097 },
      drawingManager: null,
      map: null,
      markers: [],
      polyArr: [],
      time: "",
      markpostion: {
        lat: "",
        lng: "",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.GetGpsList();
    });
  },
  created() {},
  methods: {
    moment: moment,
    // 选择Gps
    chooseGpsId(item) {
      this.gpsId = item.gpsId;
      let arr = item.homeAddress.split(" ");
      this.markers = [];
      arr.map((item) => {
        let arr2 = item.split(",");
        this.markers.push({ lng: Number(arr2[0]), lat: Number(arr2[1]) });
      });
      this.center = this.markers[0];
      this.leftList = this.polyArr.filter((item2) => {
        return item2.gpsId == item.gpsId;
      });
    },
    // 根据时间戳转成时间
    GetDate(times) {
      let date = new Date(times);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 获取 gps 轨迹
    async GetGpsList() {
      let values = await this.tableForm2.validateFields();
      let params = {
        beginSec: parseInt(
          new Date(
            values.dates[0].format(this.dateFormat) + " 00:00:00"
          ).getTime() / 1000
        ),
        endSec: parseInt(
          new Date(
            values.dates[1].format(this.dateFormat) + " 00:00:00"
          ).getTime() / 1000
        ),
      };
      params.gpsId = this.gpsId;
      apiGetGpsRecords(params).then((res) => {
        this.polyArr = [];
        res.vos.map((item1, index) => {
          let obj1 = {
            path: [],
            gpsId: item1.gpsId,
            options: {
              strokeColor: "#F56C6C",
              strokeOpacity: 1.0,
              strokeWeight: 5,
            },
          };
          if (item1.gpsRecordVos && item1.gpsRecordVos.length > 0) {
            item1.gpsRecordVos.map((item2) => {
              let obj2 = {
                status: item2.online,
                time: this.GetDate(1000 * item2.timestamp),
                lat: Number(item2.latitude),
                lng: Number(item2.longitude),
              };
              obj1.path.push(obj2);
            });
          }
          this.polyArr.push(obj1);
        });
        this.leftList = this.polyArr.filter((item2) => {
          return item2.gpsId == this.gpsId;
        });
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang="less"></style>
