import { _axios } from '@/plugins/axios'

// 获取gps列表
export const apiGetGpsList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/gps/list`,
		method: method,
		data: dataJson
	})
}

// 获取车辆详情
export const apiGetGpsDetail = (carId, method = 'get') => {
	return _axios({
		url: `/car/gps/detail/${carId}`,
		method: method,
		data: {}
	})
}

// 新增或修改车辆与gps信息
export const apiPostGps = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/gps/saveOrUpdate`,
		method: method,
		data: dataJson
	})
}

// 获取gps记录列表
export const apiGetGpsRecords = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/gps/records`,
		method: method,
		data: dataJson
	})
}

// 设置gps预警配置
export const apiGetGpsUpdate = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/gps/update`,
		method: method,
		data: dataJson
	})
}

// 更新通知状态
export const apiUpdateNotify = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/gps/update/notify`,
		method: method,
		data: dataJson
	})
}

// 获取gps预警配置
export const apiGetGpsConfig = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/warning/config`,
		method: method,
		data: dataJson
	})
}

// 更新gps预警配置
export const apiUpdateGpsConfig = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/warning/config/update`,
		method: method,
		data: dataJson
	})
}

// 获取gps token
export const getGpsToken = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/gps/token`,
		method: method,
		data: dataJson
	})
}
